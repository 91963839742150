let form = document.querySelector('.needs-validation');
let confirmBtn = document.getElementById('confirmRegistrationBtn');
let submitBtn = document.querySelector('.submitButton');
let signupCheckBtn = document.getElementById('signupCheckBtn');


// 驗證表單
(function () {
    'use strict'

    form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }
        form.classList.add('was-validated');
    }, false);

    console.log(signupCheckBtn);

    if (signupCheckBtn === null) {
        submitBtn.addEventListener('click', function (event) {
            if (!form.checkValidity()) {
                event.preventDefault();
                event.stopPropagation();
            }
            form.classList.add('was-validated');
        });
    }
    // else {
    // submitBtn.addEventListener('click', function (event) {
    //     if (!form.checkValidity()) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     }
    //     form.classList.add('was-validated');
    // });}
})();

// 驗證密碼
function checkPassword(confirm) {
    let firstPassword = document.getElementById('firstPassword').value;
    let secondPassword = confirm.value;
    let secondPasswordInput = document.getElementById('secpassword');
    let pattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
    let validationMessage = '';

    // 清除之前的验证样式
    secondPasswordInput.classList.remove('was-validated');
    secondPasswordInput.classList.remove('is-invalid');

    if (!pattern.test(secondPassword)) {
        validationMessage += "請輸入6位(含)以上英文或數字組合密碼";
    }

    if (firstPassword !== secondPassword) {
        if (validationMessage !== '') {
            validationMessage += ' 並 ';
        }
        validationMessage += "與上方密碼不一致";
        // 将输入字段标记为验证不成功的状态
        secondPasswordInput.classList.add('is-invalid');
    } else {
        // 如果密码匹配，移除验证不成功的样式
        secondPasswordInput.classList.remove('is-invalid');
    }

    document.getElementById('passwordValidationMessage').textContent = validationMessage;

    return validationMessage === '';
}

// 驗證生日
let dateInput = document.querySelector("signupbirth");

function checkDate(dateInput) {

    if (dateInput === null) {

    } else {
        var selectedDate = new Date(dateInput.value);
        var currentDate = new Date();
        var validationMessage = '';


        if (selectedDate > currentDate) {
            validationMessage = "日期不可大於今日";
            dateInput.classList.add('is-invalid');
        } else {
            validationMessage = '';
            dateInput.classList.remove('is-invalid');
        }

        document.querySelector('.dateValidationMessage').textContent = validationMessage;

        return validationMessage === '';
    }
}

//取消時清空資料
let formCancelBtn = document.querySelector('.formCancelBtn');

function CancelFromInput() {
    formCancelBtn.addEventListener('click', function () {
        form.reset();
    })
}




